import type { IntlShape } from 'react-intl-next';

// We have deprecated emotion. Please use compiled instead
// eslint-disable-next-line no-restricted-imports, @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from '@emotion/styled';

import { token } from '@atlaskit/tokens';

import { fg } from '@confluence/feature-gating';

import { inlineHighlightStyles } from '../styled';

// CCECO-4179 Leaving these line-heights here as they are addressing specific bugs and UI inconsistencies.
// eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage/preview, @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
export const StyledRenderer = styled.div<{
	isFabric?: boolean;
	markerRefs: string[] | [];
	bannerHeight?: number;
	intl: IntlShape; // used to translate messages
}>`
	// CONFCLOUD-72431 Style overrides for code blocks nested within TOC Zone macro in fabric renderer
	.ak-renderer-document [data-macro-name='toc-zone'] [data-macro-name='code'] {
		display: inline-block;
		width: calc(100% - 15px);
		background: ${token('elevation.surface', '#fff')};
	}

	.ak-renderer-document [data-macro-name='toc-zone'] [data-macro-name='code'] > div {
		line-height: 0;
	}

	.ak-renderer-document [data-macro-name='toc-zone'] [data-macro-name='code'] td.code {
		display: inline-block;
		width: calc(100% - 15px);
		padding: 0 0 0 15px;
		background: ${token('elevation.surface', '#fff')};
	}

	// when not SSR-ed, the bullet items shift vertically causing CLS because the margin from the batch css is not applied while loading
	ul,
	ol {
		margin: 10px 0 0 0;
	}

	ul:first-child,
	ol:first-child {
		margin-top: 0;
	}

	// CEMS-3057 - adds fix for initial page navigation scroll to hash link scrolling behind nav bar
	${({ bannerHeight }) =>
		bannerHeight &&
		`
    h1, h2, h3, h4, h5, h6 {
      scroll-margin-top: ${bannerHeight}px;
    }
    // fix for floating table header
    td > h1, td > h2, td > h3, td > h4, td > h5, td > h6 {
      scroll-margin-top: ${bannerHeight * 2.5}px;
    }
  `}

	${({ markerRefs, isFabric, intl }) =>
		markerRefs && inlineHighlightStyles(markerRefs, isFabric, intl)}
  ${({ isFabric }) =>
		isFabric &&
		`
    .renderer-overrides {
      /**
       * Reduce content shifting after superbatch CSS loads
       * The !important below is necessary (alas!) as editor migration to emotion
       * changed the order of applying
       */
      h1 {
        font-size: 1.714em;
        font-weight: ${fg('platform_editor_typography_ugc') ? '600' : '500'};
        line-height: 1.166;
        margin-top: ${fg('platform_editor_typography_ugc') ? '1.45833em' : '1.667em'};
      }
      h2 {
        font-size: 1.43em;
        font-weight: ${fg('platform_editor_typography_ugc') ? '600' : '500'};
        line-height: 1.2;
        margin-top: ${fg('platform_editor_typography_ugc') ? '1.4em' : '1.8em'};
      }
      h3 {
        font-size: 1.142em;
        line-height: 1.5;
        margin-top: ${fg('platform_editor_typography_ugc') ? '1.31249em' : '2em'};
      }
      h4 {
        font-size: 1em;
        line-height: 1.428;
        margin-top: ${fg('platform_editor_typography_ugc') ? '1.25em' : '1.357em'};
      }
      h5 {
        font-size: 0.857em;
        line-height: 1.333;
        margin-top: ${fg('platform_editor_typography_ugc') ? '1.45833em' : '1.667em'};
      }
      h6 {
        line-height: 1.454;
        font-weight: ${fg('platform_editor_typography_ugc') ? '600' : '700'};
        font-size: 0.785em;
        margin-top: ${fg('platform_editor_typography_ugc') ? '1.59091em' : '1.455em'};
      }
      /**
      * React Renderer dynamically adds a heading-anchor-wrapper to hide the header link icon
      * This wrapper doesn't load via SSR so we have to manually hide the icon
      */
      h1, h2, h3, h4, h5, h6 {
        > button {
          position: absolute;
          right: auto;

          > span[role="img"] {
            opacity: 0;
          }
        }
      }
      blockquote {
        border-left: 1px solid #ccc;
        margin-left: 19px;
        padding: 10px 20px;
      }
      // CEMS-2974 TOC macro with decimal number was having first number cut off due to padding
      .toc-macro[data-cssliststyle="decimal"] > ul {
        padding-left: ${token('space.500', '40px')}};
      }
      // CONFCLOUD-73546 / CEMS-3103 overrides to fix margin-top: 0 on centered headings
      .fabric-editor-block-mark[data-align] > h1, .fabric-editor-indentation-mark > h1, .fabric-editor-indentation-mark.fabric-editor-block-mark:first-of-type > h1:first-child {
          margin-top: ${token('space.500', '40px')};
        }
        .fabric-editor-block-mark[data-align] > h2, .fabric-editor-indentation-mark > h2 {
          margin-top: 28px;
        }
        .fabric-editor-block-mark[data-align] > h3, .fabric-editor-indentation-mark > h3 {
          margin-top: ${token('space.300', '24px')};
        }
        .fabric-editor-block-mark[data-align] > h4, .fabric-editor-indentation-mark > h4 {
          margin-top: ${token('space.150', '16px')};
        }
         .fabric-editor-block-mark[data-align] > h5, .fabric-editor-indentation-mark > h5 {
          margin-top: ${token('space.250', '20px')};
         }
        .fabric-editor-block-mark[data-align] > h6, .fabric-editor-indentation-mark > h6 {
          margin-top: ${token('space.200', '16px')};
        }
        .ak-ul li > ul {
          margin-top: ${token('space.050', '4px')};
        }
        ul.childpages-macro li {
          &::before {
            line-height: 24px;
          }
        }
    }
  `};

	${({ isFabric }) =>
		!isFabric &&
		`// css styling for Tiny pages

    /**
    * Sortable Tables
    */

   /* BEGIN: override default css-reset from atlaskit */
   .wiki-content table.confluenceTable {
     width: initial;
   }

   .wiki-content table.confluenceTable td:first-child,
   th:first-child {
     padding-left: 10px !important;
   }

   .wiki-content table.confluenceTable td:last-child,
   th:last-child {
     padding-right: 10px !important;
   }
   /* END: override default css-reset from atlaskit */

   .wiki-content
    table.confluenceTable
    th.confluenceTh
    > .tablesorter-header-inner
    > p {
    font-weight: ${token('font.weight.bold')};
    margin-bottom: 0;
  }

  .wiki-content
    table.confluenceTable
    td.confluenceTd
    > .tablesorter-header-inner
    > :first-child,
  .wiki-content
    table.confluenceTable
    th.confluenceTh
    > .tablesorter-header-inner
    > :first-child {
    /* CONF-26496 - Should be zero, but this causes the border to be rendered 2px high in some cases in Chrome */
    margin-top: 0.2px;
  }

  .wiki-content table.tablesorter > thead > tr > th {
    background: var(--ds-background-accent-gray-subtlest, #f0f0f0) center right no-repeat;
    padding-right: 15px;
    cursor: pointer;
  }

  .wiki-content table.tablesorter > thead > tr:hover > th {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAAKBAMAAACQ3rmwAAAAA3NCSVQICAjb4U/gAAAAD1BMVEX///9QYXNQYXNQYXNQYXNa92HSAAAABXRSTlMAESIzRJTdRHwAAAAJcEhZcwAACxIAAAsSAdLdfvwAAAAedEVYdFNvZnR3YXJlAEFkb2JlIEZpcmV3b3JrcyBDUzUuMasfSOsAAAB5dEVYdG5hbWVTZXRzAHsgZGVzY3JpcHRpb246IkRvd25sb2FkIHRoZSBcIk5hbWVkIFNlbGVjdGlvbiBTZXRzXCIgcGFuZWwgZnJvbSBodHRwOi8vZmlyZXdvcmtzLmFiZWFsbC5jb20iLCBpZDotMSwgc2V0czp7ICB9IH1YpK+bAAAAFXRFWHRDcmVhdGlvbiBUaW1lADI5LzMvMTJcby01AAAAJUlEQVQImWNgUGIAAUYXARDF4uIIokxcnBkwAFRQxMUBSQNEOwB/EQOGsCyAPwAAAABJRU5ErkJggg==);
  }

  .wiki-content table.tablesorter > thead > tr > th.tablesorter-headerSortDown {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAAKBAMAAACQ3rmwAAAAA3NCSVQICAjb4U/gAAAAHlBMVEX///9QYXNQYXNQYXNQYXNQYXNQYXNQYXNQYXNQYXOMgbQDAAAACnRSTlMAESIzRGZ3maq7ZAl7FAAAAAlwSFlzAAALEgAACxIB0t1+/AAAAB50RVh0U29mdHdhcmUAQWRvYmUgRmlyZXdvcmtzIENTNS4xqx9I6wAAAHl0RVh0bmFtZVNldHMAeyBkZXNjcmlwdGlvbjoiRG93bmxvYWQgdGhlIFwiTmFtZWQgU2VsZWN0aW9uIFNldHNcIiBwYW5lbCBmcm9tIGh0dHA6Ly9maXJld29ya3MuYWJlYWxsLmNvbSIsIGlkOi0xLCBzZXRzOnsgIH0gfVikr5sAAAAVdEVYdENyZWF0aW9uIFRpbWUAMjkvMy8xMlxvLTUAAAAdSURBVAiZY2AIYwAB5pkOIEpiZhOIqpw5nYEUAADZjgQPuazuBAAAAABJRU5ErkJggg==);
  }

  .wiki-content table.tablesorter > thead > tr > th.tablesorter-headerSortUp {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAAKBAMAAACQ3rmwAAAAA3NCSVQICAjb4U/gAAAAHlBMVEX///9QYXNQYXNQYXNQYXNQYXNQYXNQYXNQYXNQYXOMgbQDAAAACnRSTlMAESIzRGZ3maq7ZAl7FAAAAAlwSFlzAAALEgAACxIB0t1+/AAAAB50RVh0U29mdHdhcmUAQWRvYmUgRmlyZXdvcmtzIENTNS4xqx9I6wAAAHl0RVh0bmFtZVNldHMAeyBkZXNjcmlwdGlvbjoiRG93bmxvYWQgdGhlIFwiTmFtZWQgU2VsZWN0aW9uIFNldHNcIiBwYW5lbCBmcm9tIGh0dHA6Ly9maXJld29ya3MuYWJlYWxsLmNvbSIsIGlkOi0xLCBzZXRzOnsgIH0gfVikr5sAAAAVdEVYdENyZWF0aW9uIFRpbWUAMjkvMy8xMlxvLTUAAAAdSURBVAiZY2AgGlTOnA6iNGY2giiWmQZg0VQQAQBOSAQedpgI7AAAAABJRU5ErkJggg==);
  }

  /**
    * image positions
    */
  .wiki-content .image-center-wrapper {
    display: table;
    margin-left: auto;
    margin-right: auto;
  }

  .wiki-content .image-right-wrapper {
    display: table;
    margin: 0 0 10px auto;
  }

  .wiki-content .image-left-wrapper {
    display: table;
    margin: 0 auto 10px 0;
  }

  .wiki-content .confluence-embedded-file-wrapper .confluence-embedded-image {
    display: block;
    margin-left: ${token('space.025', '2px')};
    margin-right: ${token('space.025', '2px')};;
    max-width: calc(100% - 4px);
  }

   /**
    * headers
    */

  .wiki-content h1 {
    font-size: 1.714em;
    font-weight: ${token('font.weight.regular')};
    line-height: 1.166;
  }

  .wiki-content h2 {
    font-size: 1.43em;
    font-weight: ${token('font.weight.regular')};
    line-height: 1.2;
  }

  .wiki-content h3 {
    font-size: 1.142em;
    font-weight: ${token('font.weight.regular')};
    line-height: 1.5;
  }

  .wiki-content h4 {
    font-size: 1em;
    line-height: 1.428;
    font-weight: ${token('font.weight.bold')};
  }

  .wiki-content h5 {
    font-size: 0.857em;
    line-height: 1.333;
    font-weight: ${token('font.weight.bold')};
  }

  .wiki-content h6 {
    line-height: 1.454;
    font-size: 0.785em;
    font-weight: ${token('font.weight.bold')};
  }

  h1 + h2,
  h2 + h3,
  h3 + h4,
  h4 + h5,
  h5 + h6 {
    margin-top: 10px;
  }

  /**
   * quotes element
   *
   * The same styling is curerntly used for fabric pages. This fixes the issue where quote styling is not applied when a tiny page is SSR-ed. Also fixes the CLS when the page is not SSR-ed.
   */

  .wiki-content blockquote {
    border-left: 1px solid #ccc;
    color: #707070;
    margin-left: 19px;
    padding: 10px 20px;
  }

  .wiki-content blockquote p {
    margin: 10px 0 0 0;
  }

  .wiki-content blockquote p:first-of-type {
    margin-top: 0;
  }

  // the list item markers start all the way from left and then move to center/right when not SSR-ed
  li[style*='text-align: center'], li[style*='text-align: right'] {
    list-style-position: inside;
  }
  `};
`;
