import type { VFC } from 'react';
import { memo, useCallback } from 'react';

import { browserMetrics } from '@atlassian/browser-metrics';
import type { PageSegmentLoadMetric } from '@atlassian/browser-metrics/types';

import { SSRMeasures } from '@confluence/action-measures';
import { Attribution, withTransparentErrorBoundary } from '@confluence/error-boundary';

import { ActionTiming, usePerformanceAction } from './usePerformanceAction';
import { PAGE_SEGMENT_MARK_PREFIX_START } from './metric-keys';

export type PageSegmentLoadStartProps = {
	/**
	 * The metric to stop.
	 */
	metric: PageSegmentLoadMetric;
	/**
	 * A callback that is fired right after the metric is started.
	 */
	onComplete?: () => void;
	/**
	 * The timing to use when starting the metric.
	 */
	timing?: ActionTiming;
	/**
	 * isCustomStart is used to indicate how to measure event start
	 * isCustomStart = true indicates to start event measure from component is mounted as opposed to page load start
	 * isCustomStart = false (default) indicates to start event measure from Page load
	 */

	isCustomStart?: boolean;
};

const noop = () => {};

const PageSegmentLoadStartComponent: VFC<PageSegmentLoadStartProps> = memo(
	({ metric, onComplete = noop, timing = ActionTiming.RENDER_BODY, isCustomStart = false }) => {
		// eslint-disable-next-line check-react-ssr-usage/no-react-ssr
		if (process.env.REACT_SSR) {
			SSRMeasures.markMeasureStart(`ssr-app/render/${metric.key}`);
		}
		const handlePageLoadMetricStart = useCallback(() => {
			if (metric) {
				browserMetrics.getPageLoadMetric().mark(`${PAGE_SEGMENT_MARK_PREFIX_START}:${metric.key}`);
				if (isCustomStart) {
					metric.start();
				} else {
					metric.startFromPageLoad();
				}
				onComplete();
			}
		}, [metric, onComplete, isCustomStart]);

		usePerformanceAction(handlePageLoadMetricStart, timing);
		return null;
	},
);

export const PageSegmentLoadStart = withTransparentErrorBoundary({
	attribution: Attribution.BACKBONE,
})(PageSegmentLoadStartComponent);
