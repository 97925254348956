import gql from 'graphql-tag';

export const MacrosByIdsQuery = gql`
	query MacrosByIdsQuery($cloudId: ID!, $contentId: ID!, $macroIds: [ID!]!) {
		confluence_macrosByIds(cloudId: $cloudId, contentId: $contentId, macroIds: $macroIds) {
			macroId
			renderedMacro {
				value
			}
		}
	}
`;
